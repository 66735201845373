import { alertController } from '@ionic/vue';
import capacitorConstant from '../../constants/capacitor.constant';

let lockRequest = false;

const softRequestPermission = () => new Promise((resolve) => {
  if (!lockRequest) {
    lockRequest = true;
    alertController
      .create({
        header: capacitorConstant.permissionAlert.geoLocation.title,
        message: capacitorConstant.permissionAlert.geoLocation.message,
        buttons: [
          {
            text: 'No',
            handler() {
              resolve(false);
            },
          },
          {
            text: 'Yes',
            handler() {
              resolve(true);
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }
  setTimeout(() => {
    lockRequest = false;
  }, 1500);
});

export { softRequestPermission };
