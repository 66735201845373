<template>
  <ion-button
    id="btn-layer"
    fill="solid"
    color="secondary"
    @click="locateMe()"
  >
    <ion-icon
      slot="icon-only"
      :icon="locate"
    />
  </ion-button>
</template>
<script>
import { locate } from 'ionicons/icons';
import { IonIcon, IonButton } from '@ionic/vue';
import { mapGetters, mapActions } from 'vuex';
import { USER_GETTERS_ENUM, USER_ACTIONS_ENUM } from '../store/modules/user/user.enum';
import AppConst from '../constants/app.constant';
import { STORE_NAMESPACE } from '../store/modules/store.namespace';
import {
  getKeyValueFromLocalStorage,
} from '../services/localstorage/getKeyValueFromLocalStorage';
import {
  setKeyValueInLocalStorage,
} from '../services/localstorage/setKeyValueInLocalStorage';
import { checkGeoPermission } from '../services/capacitor/checkGeoPermission';
import { softRequestPermission } from '../services/capacitor/softRequestPermission';

export default {
  components: {
    IonIcon,
    IonButton,
  },
  emits: ['recenterToCurrentLocation'],
  data() {
    return {
      locate,
    };
  },
  computed: {
    ...mapGetters(STORE_NAMESPACE.USER, {
      userCurrentPosition: USER_GETTERS_ENUM.GET_USER_LOCATION,
    }),
  },
  methods: {
    ...mapActions(STORE_NAMESPACE.USER, {
      getUserLocation: USER_ACTIONS_ENUM.GET_USER_LOCATION,
    }),
    askForPermission() {
      const firstSoftAsk = getKeyValueFromLocalStorage(
        AppConst.localstorageConf.firstSoftAskGeolocationAccess,
      );
      if (!firstSoftAsk) {
        setKeyValueInLocalStorage(AppConst.localstorageConf.firstSoftAskGeolocationAccess, true);
        softRequestPermission().then((allowed) => {
          if (allowed) {
            this.getUserLocation().then((data) => {
              if (data) {
                this.$emit('recenterToCurrentLocation');
              }
            });
          }
        });
      } else if (checkGeoPermission()) {
        this.getUserLocation().then((data) => {
          if (data) {
            this.$emit('recenterToCurrentLocation');
          }
        });
      }
    },
    locateMe() {
      if (this.userCurrentPosition) {
        this.$emit('recenterToCurrentLocation');
      }
      this.askForPermission();
    },
  },
};
</script>
