import * as airportTabsMetsData from '../../public/static/json/airportTabsMeta.json';

const airportTabsMets = airportTabsMetsData.default;

export default {
  observationsTabs: [
    {
      key: airportTabsMets.metars.key,
      label: airportTabsMets.metars.label,
      icon: 'binoculars',
      goToView: 'dashboard',
      fontawesome: true,
    },
    {
      key: airportTabsMets.radar.key,
      label: airportTabsMets.radar.label,
      icon: 'binoculars',
      goToView: 'dashboard',
      fontawesome: true,
    },
    {
      key: airportTabsMets.skewT.key,
      label: airportTabsMets.skewT.label,
      icon: 'binoculars',
      goToView: 'dashboard',
      fontawesome: true,
    },
  ],
  forecastTabs: [
    {
      key: 'meteogram',
      label: 'Meteogram',
      icon: 'trendingUp',
      fontawesome: false,
      goToView: 'dashboard',
    },
    {
      key: 'weather',
      label: '7-Day',
      icon: 'trendingUp',
      fontawesome: false,
      goToView: 'weather',
    },
    {
      key: airportTabsMets.tafs.key,
      label: airportTabsMets.tafs.label,
      icon: 'trendingUp',
      goToView: 'dashboard',
      fontawesome: false,
    },
    {
      key: airportTabsMets.forecastDiscussion.key,
      label: airportTabsMets.forecastDiscussion.label,
      goToView: 'dashboard',
      icon: 'trendingUp',
      fontawesome: false,
    },
  ],
};
